<template>
  <ReporteLayout
    titulo="Reportes - Ventas Generales"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
    tipoTransaccion="venta"
  />
</template>

<script>
import ReporteLayout from "../../components/ReporteLayout.vue";
import ReporteVentasService from "../services";

export default {
  name: "ReporteVentasGeneralesLayout",
  components: {
    ReporteLayout,
  },
  data() {
    return {
      componenteListado: "ListadoTransaccionesGenerales",
    };
  },
  methods: {
    servicioGetReporte: ReporteVentasService.getReporteVentasGenerales,
    servicioGetReportePdf: ReporteVentasService.getReporteVentasGeneralesPdf,
  },
};
</script>